@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');

.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.body-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.body-wrapper .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.body-wrapper .gift {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.4;
}

@media screen and (min-width: 600px) {
  .body-wrapper .gift {
    display: none;
  }
}
@media screen and (max-width: 280px) {
  .body-wrapper .gift {
    display: none;
  }
}

.title {
  font-size: 1.8em;
  margin: 0.5em 0;
  font-weight: bold;
}

.sub-title {
  margin-top: 2rem;
  font-size: 1.2em;
}

.verified-title {
  margin-top: 2rem;
  font-size: 1.7em;
  color: green;
  font-weight: bold;
}

.btn {
  margin-top: 1rem;
  display: block;
  width: 17rem;
  height: 4rem;
  border-radius: 20px;
  outline: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif !important;
}

.image {
  width: 250px;
  height: 100%;
  border: 0;
}

.not-found-img {
  width: 350px;
  height: 100%;
}

.not-found-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-found-wrapper .not-found-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-found-btn {
  border: none;
  outline: none;
  text-decoration: none;
  width: 18rem;
  height: 3rem;
  font-size: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  border-radius: 40px;
  background: #4c8bf5;
  color: #fff;
  cursor: pointer;
}
